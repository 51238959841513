<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="任务名称">
                            <a-input :disabled="true" v-decorator="[
                                'name',
                                {
                                    initialValue: detail.name,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" placeholder="请输入任务名称" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="任务时间">
                            <a-range-picker :disabled="true" v-decorator="[
                                'date',
                                {
                                    initialValue: detail.date,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备注">
                            <a-input :disabled="true" v-decorator="[
                                'remark',
                                {
                                    initialValue: detail.remark,
                                },
                            ]" placeholder="请输入备注" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="单位" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                            <Company :list="companyList" :showBtn="false"/>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
                        <a-form-item label="项目" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                            <Project :list="projectList" :showBtn="false"/>
                        </a-form-item>
                    </a-col>

                </a-row>

                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import request from "@/api/request";
import Company from "./components/Company.vue";
import Project from "./components/Project.vue";
import organization from "@/mixins/organization";
function getDetail(id) {
  return request({
    url: "/office-service/quality/risk/task/detail/" + id,
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    components: {
        Company,
        Project
    },

    data() {
        return {
            form: this.$form.createForm(this),
            companyList: [],
            projectList: [],
            loading: false,
            detail: {}
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.detail = res;
            this.detail.date = [res.startDate, res.endDate]
            this.companyList = res.deptList;
            this.projectList = res.projectList;
        });
    },
    methods: {

    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>